import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { LeadCaptureForm } from '../lead_capture_form'
import {
  useLeadCaptureForm,
  useMembers,
  useStep,
  useFamilyCreation,
  useMembershipTemplates,
  useError,
  useClasses,
  usePreview,
} from '../landing_page'
import { useComputed, useSignal, useSignalEffect } from '@preact/signals'
import { MembershipSelection } from './membership'
import { ClassSelection } from './classes'

export function SignUp() {
  const membershipTemplates = useMembershipTemplates()
  const { classes } = useClasses()
  const { members, ensureParticipant } = useMembers()
  const { nextStep } = useStep()
  const { setError } = useError()
  const familyCreation = useFamilyCreation()
  const isPreview = usePreview()

  // When we do multiple people, we'll want to move these into a
  // per-person Context.
  const primaryMember = members[0]
  const participants = members.slice(1)
  const participating = useSignal(primaryMember.participating)
  const showParticipants = !familyCreation && !participating.value
  const membershipSelected = useSignal(!membershipTemplates)
  const classSelected = useSignal(!classes)

  const nextStepUnlessInvalid = (event) => {
    event.preventDefault()

    if (!membershipSelected.value) {
      setError({ membership: 'Select a Membership' })
      return
    }

    if (!isPreview && !classSelected.value) {
      setError({ class: 'Select a Class' })
      return
    }

    if (event.target.checkValidity()) {
      nextStep()
    }
  }

  useSignalEffect(() => (primaryMember.participating = participating.value), [participating])

  useSignalEffect(() => {
    if (!participating.value) {
      ensureParticipant()
    }
  }, [participating])

  return (
    <form onSubmit={nextStepUnlessInvalid}>
      <div class="p-3 bg-[var(--shadow-color)] space-y-3">
        <PrimaryMember
          member={primaryMember}
          participating={participating}
          membershipSelected={membershipSelected}
          classSelected={classSelected}
        />

        {showParticipants &&
          participants.map((member) => (
            <ParticipantMember
              member={member}
              membershipSelected={membershipSelected}
              classSelected={classSelected}
            />
          ))}
      </div>
      <div class="flex justify-between items-center gap-4 mt-4 pb-4">
        <div class="label required text-[var(--secondary-color)]">required</div>

        <button class="btn" type="submit">
          NEXT
        </button>
      </div>
    </form>
  )
}

function PrimaryMember({ member, participating, membershipSelected, classSelected }) {
  const { leadCaptureForm, guardianLeadCaptureForm } = useLeadCaptureForm()

  return (
    <>
      {guardianLeadCaptureForm && (
        <div class="p-3 bg-[var(--tertiary-color)]">
          <div class="space-y-1">
            <div class="font-medium uppercase tracking-widest required">Who are you signing up?</div>

            <div class="space-y-0.5">
              <div class="flex gap-2 items-center">
                <input
                  id="participating"
                  type="radio"
                  class="radio radio-sm"
                  checked={participating.value}
                  onChange={({ target }) => (participating.value = target.checked)}
                />
                <label for="participating">Myself (18+)</label>
              </div>
              <div class="flex gap-2 items-center">
                <input
                  id="not-participating"
                  type="radio"
                  class="radio radio-sm"
                  checked={!participating.value}
                  onChange={({ target }) => (participating.value = !target.checked)}
                />
                <label for="not-participating">Participant under 18</label>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="p-3 bg-[var(--tertiary-color)]">
        <div class="text-base font-bold">
          {participating.value ? 'Your contact info' : 'Guardian contact info'}
        </div>

        <div class="space-y-3">
          {guardianLeadCaptureForm ? (
            <>
              {participating.value ? (
                <ParticipantInfo
                  member={member}
                  leadCaptureForm={leadCaptureForm}
                  membershipSelected={membershipSelected}
                  classSelected={classSelected}
                />
              ) : (
                <LeadCaptureForm member={member} leadCaptureForm={guardianLeadCaptureForm} />
              )}
            </>
          ) : (
            <ParticipantInfo
              member={member}
              leadCaptureForm={leadCaptureForm}
              membershipSelected={membershipSelected}
              classSelected={classSelected}
            />
          )}
        </div>
      </div>
    </>
  )
}

function ParticipantMember({ member, membershipSelected, classSelected }) {
  let { leadCaptureForm, guardianLeadCaptureForm } = useLeadCaptureForm()

  const usePrimaryInfo = useSignal(member.usePrimaryInfo)

  useSignalEffect(() => (member.usePrimaryInfo = usePrimaryInfo.value), [usePrimaryInfo])

  if (usePrimaryInfo.value) {
    leadCaptureForm = leadCaptureForm.filter((field) => {
      if (!guardianLeadCaptureForm.map((f) => f.key).includes(field.key)) {
        return true
      }

      return !['phone', 'email', 'address'].includes(field.key)
    })
  }

  return (
    <div className="p-3 bg-[var(--tertiary-color)]">
      <div class="text-base font-bold mb-3">Participant info</div>

      <div className="space-y-3">
        <div className="flex items-center gap-2">
          <span class={usePrimaryInfo.value ? '' : 'text-[var(--secondary-color-light)]'}>
            Use Guardian contact info
          </span>
          <input
            type="checkbox"
            class="toggle toggle-xs"
            checked={usePrimaryInfo.value}
            onChange={({ target }) => (usePrimaryInfo.value = target.checked)}
          />
        </div>

        <ParticipantInfo
          member={member}
          leadCaptureForm={leadCaptureForm}
          membershipSelected={membershipSelected}
          classSelected={classSelected}
        />
      </div>
    </div>
  )
}

function ParticipantInfo({ member, leadCaptureForm, membershipSelected, classSelected }) {
  return (
    <>
      <LeadCaptureForm member={member} leadCaptureForm={leadCaptureForm} />

      <MembershipAndClassSelection
        member={member}
        membershipSelected={membershipSelected}
        classSelected={classSelected}
      />
    </>
  )
}

function MembershipAndClassSelection({ member, membershipSelected, classSelected }) {
  const membershipTemplates = useMembershipTemplates()
  const { hasClasses, classesFor, required } = useClasses()

  const selectedTemplate = useSignal(member.membershipTemplate)
  const selectedProgram = useSignal(member.program)
  const availableClasses = useComputed(() => {
    if (!hasClasses) return
    if (!membershipSelected.value) return
    if (!selectedTemplate.value) return
    if (!selectedProgram.value) return

    return classesFor(selectedProgram.value.id)
  })

  const current = availableClasses.value?.find(({ name }) => name === member.class?.name)
  const selectedClass = useSignal(null)
  if (current) {
    selectedClass.value = current
  }

  useSignalEffect(() => {
    if (required || Boolean(selectedClass.value)) {
      classSelected.value = !availableClasses.value
      return
    }

    classSelected.value = true
  })

  return (
    <>
      {membershipTemplates && (
        <MembershipSelection
          member={member}
          completed={membershipSelected}
          selectedTemplate={selectedTemplate}
          selectedProgram={selectedProgram}
        />
      )}
      {availableClasses.value && (
        <ClassSelection
          member={member}
          completed={classSelected}
          availableClasses={availableClasses}
          selectedClass={selectedClass}
          selectedProgram={selectedProgram}
        />
      )}
    </>
  )
}
