import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useBilling, useTheme } from '../../landing_page'
import { useCart } from '../cart'
import { GenericConfirmation } from './generic'
import { useSignal } from '@preact/signals'
import { post } from '../../../helpers/fetch_helper'
import { useSubmit } from '../submission'
import { Error } from '../error'

let globalStripe

export function StripeConfirmation() {
  const { merchantAccount } = useBilling()
  const { total } = useCart()

  globalStripe ||= window.Stripe(merchantAccount.publicKey, { stripeAccount: merchantAccount.account })

  return (
    <Elements
      stripe={globalStripe}
      options={{
        mode: 'payment',
        amount: Math.floor(total()),
        currency: document.body.dataset.currency.toLowerCase(),
      }}
    >
      <Confirmation />
    </Elements>
  )
}

function Confirmation() {
  const stripe = useStripe()
  const elements = useElements()

  const { cart, isMembership } = useCart()
  const { strictVaulting } = useBilling()
  const { submit: defaultSubmit } = useSubmit()

  const save = useSignal(true)
  const errorText = useSignal(null)
  const disabled = useSignal(!stripe)

  const submit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) return

    disabled.value = true

    const body = {}

    cart.forEach((quantity, item) => {
      if (isMembership(item)) {
        body.membership = item.id
      } else {
        body.items ||= []

        body.items.push({
          id: item.id,
          quantity,
        })
      }
    })

    const response = await post(window.location.href.replace('/submission/new', '/payment_intents'), body)
    const { client_secret: clientSecret } = await response.json()

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: elements.getElement(CardElement) },
      setup_future_usage: 'off_session',
    })

    if (error) {
      errorText.value = error.message
      disabled.value = false
      return
    }

    const success = await defaultSubmit({
      billing: { save, label: null, source: paymentIntent.id },
    })

    if (!success) {
      disabled.value = false
    }
  }

  return (
    <GenericConfirmation
      submitDisabled={disabled.value}
      onSubmit={submit}
      PaymentElement={
        <div class="mt-2">
          <StripePaymentElement errorText={errorText} />
          <div class="mt-1">
            <Error message={errorText.value} />
          </div>
          <div class="mt-1 text-[var(--secondary-color-light)]">
            {strictVaulting ? (
              <>Card details will be saved to your profile.</>
            ) : (
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  class="checkbox checkbox-primary checkbox-sm"
                  onChange={({ target }) => (save.value = target.checked)}
                  id="save-for-later"
                  checked={save.value}
                />

                <label for="save-for-later">Save for Later</label>
              </div>
            )}
          </div>
        </div>
      }
    />
  )
}

function StripePaymentElement({ errorText }) {
  const theme = useTheme()

  return (
    <CardElement
      onChange={(event) => {
        if (event.error) {
          errorText.value = event.error.message
        } else {
          errorText.value = null
        }
      }}
      options={{
        style: {
          base: {
            iconColor: theme.primary_color,
            color: theme.secondary_color,
            lineHeight: '32px',
            fontFamily: '"Hind Vadodara", sans-serif',
            fontSize: '16px',
            textTransform: 'uppercase',

            '::placeholder': { color: `${theme.secondary_color}BF` },
          },
          invalid: {
            iconColor: '#d9433e',
            color: '#d9433e',
          },
        },
      }}
    />
  )
}
