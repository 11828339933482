/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['autocomplete', 'id', 'street', 'city', 'state', 'zip', 'country', 'lat', 'lng']
  static values = { message: String }

  initialize() {
    // A bit unfortunate that we have to do this, but Google doesn't have an NPM version of the maps API
    // that is meant to run in the browser so we have to pull it from the global scope :(
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteTarget, { types: ['address'] })
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  clearHiddenFields(event) {
    this.streetTarget.removeAttribute('value')
    this.cityTarget.removeAttribute('value')
    this.stateTarget.removeAttribute('value')
    this.zipTarget.removeAttribute('value')
    this.countryTarget.removeAttribute('value')
    this.latTarget.removeAttribute('value')
    this.lngTarget.removeAttribute('value')

    if (event.target.value === '') {
      this.autocompleteTarget.setCustomValidity('')
    } else if (this.autocompleteTarget.checkValidity()) {
      this.autocompleteTarget.setCustomValidity(this.messageValue)
    }

    this.dispatch('input')
  }

  ///
  /// private
  ///
  placeChanged() {
    const place = this.autocomplete.getPlace()
    if (place.geometry) {
      this.autocompleteTarget.value = place.formatted_address
    }

    const [street, city, state, zip, country] = this.parseComponent(place.address_components)

    this.streetTarget.value = street
    this.cityTarget.value = city
    this.stateTarget.value = state
    this.zipTarget.value = zip
    this.countryTarget.value = country
    this.latTarget.value = place.geometry.location.lat()
    this.lngTarget.value = place.geometry.location.lng()

    this.autocompleteTarget.setCustomValidity('')
    this.dispatch('input')
    this.zipTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  // A function that takes a Google API Places address component array and returns an array of strings
  // for the street, city, state, zip, and country fields.
  parseComponent(components) {
    const street_number = components.find((component) => component.types.includes('street_number'))
    const route = components.find((component) => component.types.includes('route'))
    const city = components.find((component) => component.types.includes('locality'))
    const state = components.find((component) => component.types.includes('administrative_area_level_1'))
    const zip = components.find((component) => component.types.includes('postal_code'))
    const country = components.find((component) => component.types.includes('country'))

    return [
      street_number && route ? `${street_number.long_name} ${route.long_name}` : '',
      city ? city.long_name : '',
      state ? state.long_name : '',
      zip ? zip.long_name : '',
      country ? country.long_name : '',
    ]
  }

  get validInput() {
    return Boolean(this.latTarget.value) && Boolean(this.lngTarget.value)
  }

  get submitButton() {
    return this.element.closest('form').querySelector('input[type="submit"]')
  }
}
