import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useClasses, useError, usePreview } from '../landing_page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chevron, Collapsible } from './collapsible'
import { useSignal, useSignalEffect } from '@preact/signals'
import { getCsrfToken } from '../../helpers/fetch_helper'
import { Error } from './error'
import { Select } from '../select'

export function ClassSelection({ member, completed, availableClasses, selectedClass, selectedProgram }) {
  const classes = 'size-2 rounded-full ring-2 ring-offset-[var(--shadow-color)] ring-offset-2'

  const { required } = useClasses()

  const { errors } = useError()

  defaultComplete(member, errors, completed)

  const onClassSelected = ({ target }) => {
    if (target.value) {
      selectedClass.value = availableClasses.value.find((c) => c.id.toString() === target.value)
    } else {
      selectedClass.value = null
      member.class = null
    }
  }

  return (
    <>
      <div class={`bg-[var(--shadow-color)] border ${errors.class ? 'border-danger-600' : 'border-transparent'}`}>
        <Collapsible
          defaultOpen={true}
          Trigger={
            <>
              <div class="flex items-center gap-2 size">
                <div>
                  <span class="text-base font-bold">Class</span>
                  {!required && (
                    <span class="text-sm text-[var(--secondary-color-light)] font-semibold ml-1">(Optional)</span>
                  )}
                </div>
                {completed.value && (
                  <div class="size-4 bg-[var(--tertiary-color)] rounded-full">
                    <FontAwesomeIcon icon="circle-check" className="text-[var(--primary-color)] size-4 block" />
                  </div>
                )}
              </div>
            </>
          }
          Content={
            <div class="p-3 bg-[var(--tertiary-color)] space-y-3">
              <div>
                <label class={`block font-medium uppercase tracking-widest mb-1 ${required ? 'required' : ''}`}>
                  Select a class
                </label>
                <Select onChange={onClassSelected} required={required}>
                  <option selected={!selectedClass.value} hidden value="">
                    Select a class
                  </option>
                  {!required && <option value="">None</option>}

                  {availableClasses.value.map(({ id, name }) => (
                    <option value={id} selected={id === selectedClass.value?.id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </div>

              <div class="border-t border-[var(--secondary-color-light)]"></div>

              <TimeSelector
                member={member}
                selectedProgram={selectedProgram}
                selectedClass={selectedClass}
                completed={completed}
              />
            </div>
          }
        />
      </div>

      {errors.class && <Error message={errors.class} />}
    </>
  )
}

let controller

// eslint-disable-next-line complexity
function TimeSelector({ member, selectedProgram, selectedClass, completed }) {
  const classTimes = useSignal({})
  const loadingClasses = useSignal(false)
  const isPreview = usePreview()
  const selectedEvent = useSignal(member.class)

  const fetchTimes = async (from) => {
    if (isPreview) return
    if (controller) return

    const timeout = setTimeout(() => (loadingClasses.value = true), 200)

    controller = new AbortController()

    const path = window.location.href.replace(
      '/submission/new',
      `/programs/${selectedProgram.peek().id}/classes/${selectedClass.peek().id}?from=${from}`,
    )
    const options = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'Content-Type': 'application/json',
      },
      signal: controller.signal,
    }

    const response = await fetch(path, options).finally(() => {
      clearTimeout(timeout)
      loadingClasses.value = false
      controller = null
    })

    if (response.ok) {
      classTimes.value = await response.json()
    }
  }

  if (selectedEvent.value && !classTimes.value) {
    fetchTimes(selectedClass.value.start)
  }

  useSignalEffect(async () => {
    if (!selectedClass.value) {
      classTimes.value = {}
      return
    }

    if (controller) {
      controller.abort()
      controller = null
    }

    await fetchTimes(classTimes.peek()?.prev)
  })

  const next = async () => {
    await fetchTimes(classTimes.peek().next)
  }

  const prev = async () => {
    await fetchTimes(classTimes.peek().prev)
  }

  return (
    <div>
      <div class="flex items-center justify-between">
        <div class="size-4">
          {classTimes.value.prev && (
            <button type="button" onClick={() => prev()}>
              <Chevron class="rotate-90 text-[var(--primary-color)] size-4" />
            </button>
          )}
        </div>
        <div class="relative">
          {classTimes.value.prev ? (
            <>
              <span>{formatMonthDay(classTimes.value.startTime)}</span>
              <span> - </span>
              <span>{formatMonthDay(classTimes.value.endTime)}</span>
            </>
          ) : (
            <>
              <span class="font-bold">This</span>
              <span> week</span>
            </>
          )}

          <div class="absolute -right-6 top-px size-4">{loadingClasses.value && <Loader />}</div>
        </div>
        <div class="size-4">
          {classTimes.value.next && (
            <button type="button" onClick={() => next()}>
              <Chevron class="-rotate-90 text-[var(--primary-color)] size-4" />
            </button>
          )}
        </div>
      </div>
      {classTimes.value.schedule ? (
        <div class="space-y-2 pt-3">
          <WeeklySchedule
            member={member}
            schedule={classTimes.value.schedule}
            selectedClass={selectedClass}
            selectedEvent={selectedEvent}
            completed={completed}
          />
        </div>
      ) : (
        <div class="rounded bg-[var(--shadow-color)] py-2 px-3 flex items-center justify-center mt-3">
          {isPreview ? (
            <div>Class selection unavailable in preview</div>
          ) : (
            <div>Select a class to see time slots</div>
          )}
        </div>
      )}
    </div>
  )
}

function defaultComplete(member, errors, completed) {
  if (member.class && !errors.class) {
    completed.value = true
    return
  }
}

function Loader() {
  return <FontAwesomeIcon icon="fa-duotone fa-spinner-third" className="animate-spin size-4" />
}

function WeeklySchedule({ member, schedule, completed, selectedEvent }) {
  const { clearError } = useError()

  const byDayOfWeek = schedule.reduce((acc, event) => {
    const start = new Date(event.start)
    const dayOfWeek = start.toLocaleDateString('en-US', { weekday: 'long' })
    const monthDay = start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    const key = `${dayOfWeek}, ${monthDay}`

    acc[key] ||= []
    acc[key].push(event)

    return acc
  }, {})

  const organized = Object.fromEntries(
    Object.entries(byDayOfWeek).map(([day, events]) => [
      day,
      events.sort((a, b) => new Date(a.start) - new Date(b.start)),
    ]),
  )

  const classes = 'block w-full rounded bg-[var(--tertiary-color)] p-1 flex items-center justify-center border-2'

  const eventIsSelected = (event) => {
    if (!selectedEvent.value) return false

    const selected = selectedEvent.value

    // javascript moment
    return JSON.stringify(selected) === JSON.stringify(event)
  }

  const selectedClasses = 'border-[var(--primary-color)] relative'

  return (
    <>
      {Object.entries(organized).map(([key, events]) => (
        <div key={key} class="rounded bg-[var(--shadow-color)] p-3 space-y-2">
          <div>{key}</div>
          {events.map((event) => (
            <>
              {event.selectable ? (
                <button
                  type="button"
                  key={event}
                  class={`${classes} ${eventIsSelected(event) ? selectedClasses : 'border-transparent'}`}
                  onClick={() => {
                    if (eventIsSelected(event)) {
                      member.class = null
                      selectedEvent.value = null
                      completed.value = false
                      return
                    }

                    clearError('class')
                    member.class = event
                    selectedEvent.value = event
                    completed.value = true
                  }}
                >
                  {eventIsSelected(event) && (
                    <div class="absolute -left-2 -top-2 bg-[var(--tertiary-color)] size-4 rounded-full">
                      <FontAwesomeIcon icon="circle-check" className="block text-[var(--primary-color)] size-4" />
                    </div>
                  )}

                  <div>
                    <span>{formatTime(event.start)}</span>
                    <span> - </span>
                    <span>{formatTime(event.end)}</span>
                  </div>
                </button>
              ) : (
                <div key={event} class={`${classes} border-transparent cursor-not-allowed opacity-50`}>
                  <div>
                    <span>{formatTime(event.start)}</span>
                    <span> - </span>
                    <span>{formatTime(event.end)}</span>
                    {event.tooLate && (
                      <>
                        <span> – </span>
                        <span class="font-semibold">Class full</span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      ))}
    </>
  )
}

function formatMonthDay(time) {
  const date = new Date(time)

  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
}

function formatTime(time) {
  const date = new Date(time)

  return date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase()
}
