import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useRef } from 'preact/hooks'
import { GenericConfirmation } from './generic'
import { useSubmit } from '../submission'
import { useBasysTokenizer } from '../tokenizers/basys'
import { useTheme, useBilling } from '../../landing_page'
import { useSignal } from '@preact/signals'
import { Error } from '../error'

export function BasysConfirmation() {
  const theme = useTheme()
  const { merchantAccount, strictVaulting } = useBilling()
  const { submit: defaultSubmit } = useSubmit()
  const paymentElementRef = useRef(null)

  const zip = useSignal(null)
  const label = useSignal(null)
  const save = useSignal(true)
  const errorText = useSignal(null)

  const disabled = useSignal(true)
  const tokenizer = useBasysTokenizer(
    paymentElementRef,
    theme,
    merchantAccount,
    async (token) => {
      disabled.value = true

      const success = await defaultSubmit({
        billing: { label, save, source: token, billing_address: { zip_code: zip } },
      })

      if (!success) {
        disabled.value = false
      }
    },
    () => {
      disabled.value = false
    },
    (error) => {
      disabled.value = false
      errorText.value = error
    },
  )

  const submit = (event) => {
    disabled.value = true
    event.preventDefault()

    tokenizer.submit()
  }

  return (
    <GenericConfirmation
      submitDisabled={disabled.value}
      onSubmit={submit}
      PaymentElement={
        <div class="mt-2">
          <div class=" grid grid-cols-[1fr_7em]">
            <div ref={paymentElementRef}></div>
            <div class="pl-[5px]">
              <input
                class="input input-border text-base placeholder:text-[#b8b8b8] placeholder:opacity-100"
                type="text"
                placeholder="ZIP"
                maxlength="10"
                required
                onInput={({ target }) => (zip.value = target.value)}
              />
            </div>
          </div>
          {save.value && (
            <div>
              <input
                class="input input-border text-base placeholder:text-[#b8b8b8] placeholder:opacity-100"
                type="text"
                placeholder="LABEL (OPTIONAL)"
                onInput={({ target }) => (label.value = target.value)}
              />
            </div>
          )}
          <div class="mt-1">
            <Error message={errorText.value} />
          </div>
          <div class="mt-1 text-[var(--secondary-color-light)]">
            {strictVaulting ? (
              <>Card details will be saved to your profile.</>
            ) : (
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  class="checkbox checkbox-primary checkbox-sm"
                  onChange={({ target }) => (save.value = target.checked)}
                  id="save-for-later"
                  checked={save.value}
                />

                <label for="save-for-later">Save for Later</label>
              </div>
            )}
          </div>
        </div>
      }
    />
  )
}
