import { Controller } from '@hotwired/stimulus'
import { getStream } from '../helpers/fetch_helper'

export default class ApplicationController extends Controller {
  clickOn({ params: { selector } }) {
    this.element.querySelector(selector)?.click()
  }

  setFrame({ params: { frame, src } }) {
    const frameElement = document.querySelector(`#${frame}`)
    if (!frameElement) return

    frameElement.src = src
  }

  refreshClosestFrame({ target }) {
    target.closest('turbo-frame')?.reload()
  }

  refreshFormFrame({ target, params: { url } }) {
    const params = new URLSearchParams(new FormData(target.form))
    params.delete('utf8')
    params.delete('_method')
    params.delete('authenticity_token')

    const src = `${url}?${params.toString()}`

    window.Turbo.visit(src, { frame: target.closest('turbo-frame').id })
  }

  flushAlerts() {
    getStream('/flush_notifications')
  }

  resetAlerts() {
    this.alertsFrame.innerHTML = ''
  }

  preventDefault(event) {
    event.preventDefault()
  }

  submitClosestForm({ currentTarget }) {
    currentTarget.closest('form')?.requestSubmit()
  }

  resetClosestForm({ currentTarget }) {
    const form = currentTarget.closest('form')

    if (!form) return

    form.reset()
    form.querySelectorAll('input[type="hidden"]').forEach((input) => (input.value = null))
    form.requestSubmit()
  }

  get alertsFrame() {
    return document.querySelector('#global-alerts')
  }

  get currentURL() {
    return new URL(window.location.href)
  }

  ///
  /// private
  ///

  modifyQueryStringInline(key, value) {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    if (value) {
      params.set(key, value)
    } else {
      params.delete(key)
    }

    url.search = params.toString()

    window.history.replaceState({}, '', url.toString())
  }

  clearDataAttributes(element) {
    for (const attribute of element.getAttributeNames()) {
      if (attribute.startsWith('data-')) {
        element.removeAttribute(attribute)
      }
    }
  }

  waitForAnimations(element, callback) {
    return Promise.all(element.getAnimations().map((animation) => animation.finished)).then(callback)
  }

  vibrate(duration) {
    if (navigator.vibrate) {
      navigator.vibrate(duration)
    }
  }

  focus(element) {
    if (!element) return

    document.activeElement.blur()
    element.focus()
  }
}
