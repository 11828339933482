import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useAgreements, useStep, useMembers } from './landing_page'
import { useEffect, useRef, useState } from 'preact/hooks'

export function Agreements() {
  const { members } = useMembers()
  const { nextStep, previousStep } = useStep()
  const agreements = useAgreements()
  let action =
    'signature:reset->agreement-signatures#jumpTo signature:completed->agreement-signatures#updateCount ' +
    'signature:reset->agreement-signatures#updateCount'

  const controllerRef = useRef(null)

  const [controller, setController] = useState(null)

  useEffect(() => {
    if (!controllerRef.current) {
      setController(null)
      return
    }

    const cont = window.Stimulus.getControllerForElementAndIdentifier(
      controllerRef.current,
      'agreement-signatures',
    )

    setController(cont)
  }, [controllerRef])

  useEffect(() => {
    const tokens = members[0].agreementTokens

    if (!tokens) return

    document.querySelectorAll('[data-token]').forEach((element) => {
      const token = element.getAttribute('data-token')
      element.value = tokens[token]
    })
  })

  // TODO: So to get things to populate right we'll wanna effectively make `submit`
  // handle _both_ going forward and backward I think. Seems doable?
  const submit = (event) => {
    event.preventDefault()

    if (!controller) return

    const isBack = event.submitter.value

    if (!isBack && !controller.completed) {
      controller.scrollToCurrent()
      controller.flashValidity()
      return
    }

    const formData = new FormData(event.target)
    const signatures = formData.getAll('signatures[]')
    members[0].signatures = signatures
    const tokens = {}
    event.target.querySelectorAll('[data-token]').forEach((element) => {
      const token = element.getAttribute('data-token')
      tokens[token] = element.value
    })
    members[0].agreementTokens = tokens

    if (isBack) {
      previousStep()
      return
    }

    nextStep()
  }

  return (
    <form onSubmit={submit}>
      {agreements.map((agreement) => (
        <div
          class="relative"
          data-controller="agreement-signatures"
          data-agreement-signatures-signature-outlet=".signature"
          data-agreement-signatures-checkbox-signature-outlet=".checkbox-signature"
          data-action={action}
          ref={controllerRef}
        >
          <div class="p-3 bg-[var(--shadow-color)]">
            <div class="p-3 bg-[var(--tertiary-color)] space-y-3">
              <div>
                <h4 class="text-center mb-4">{agreement.name}</h4>
                <div
                  class="fr-view"
                  dangerouslySetInnerHTML={{ __html: agreement.sanitized_agreement_content }}
                  style={{ color: 'var(--secondary-color) !important' }}
                ></div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-between items-center">
            <div class="flex m-3 gap-1">
              <div data-agreement-signatures-target="count">0</div>
              <div>of</div>
              <div>{agreement.count}</div>
              <div data-agreement-signatures-target="count">signatures completed</div>
            </div>
            <div class="flex m-3 gap-1">
              <button class="btn btn-back" type="submit" value="true" formnovalidate>
                BACK
              </button>
              <button class="btn" type="submit">
                NEXT
              </button>
            </div>
          </div>
        </div>
      ))}
    </form>
  )
}
