import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { Members } from './members'
import { Cart } from './cart'
import { useStep, useError, usePreview } from '../../landing_page'
import { Payment } from './payment'
import { Error } from '../error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function GenericConfirmation({ submitDisabled = false, onSubmit, PaymentElement = null }) {
  const { errors, clearError } = useError()
  const { previousStep } = useStep()
  const isPreview = usePreview()
  const submitText = PaymentElement ? 'Finish & Pay' : 'Finish'
  const widthClass = PaymentElement ? 'w-32' : 'w-20'

  return (
    <form onSubmit={onSubmit}>
      <div class="p-3 bg-[var(--shadow-color)] space-y-3">
        <Members />
        <Cart />

        {Boolean(PaymentElement) && <Payment PaymentElement={PaymentElement} />}
        {Boolean(errors.generic) && <Error message={errors.generic} />}
      </div>

      <div class="flex justify-end items-center gap-1 mt-4 pb-4">
        <button
          class="btn btn-back"
          onClick={() => {
            clearError('generic')
            previousStep()
          }}
          type="button"
          disabled={submitDisabled}
        >
          BACK
        </button>

        <button class={`btn ${widthClass}`} type="submit" disabled={isPreview || submitDisabled}>
          {submitDisabled ? (
            <FontAwesomeIcon icon="fa-duotone fa-spinner-third" className="animate-spin size-4" />
          ) : (
            <span>{submitText}</span>
          )}
        </button>
      </div>
    </form>
  )
}
