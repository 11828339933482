import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { original: String, syncTo: { type: String, default: 'main' }, syncAs: String }
  static targets = ['input', 'value', 'display', 'reset']

  update(syncValue = true) {
    requestAnimationFrame(() => {
      const value = this.inputTarget.value.toUpperCase()

      if (value === this.originalValue) {
        this.resetTarget.classList.add('invisible')
      } else {
        this.resetTarget.classList.remove('invisible')
      }

      if (syncValue) this.valueTarget.value = value

      this.displayTarget.style.backgroundColor = value

      this.#trySync()
    })
  }

  set({ target }) {
    const realValue = this.#massageIntoHex(target.value)

    if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(realValue)) {
      this.inputTarget.value = realValue

      this.update(false)
    }
  }

  setValueField() {
    this.valueTarget.value = this.inputTarget.value.toUpperCase()
  }

  reset() {
    this.inputTarget.value = this.originalValue

    this.update()

    this.inputTarget.dispatchEvent(new Event('input'))
    this.valueTarget.value = this.originalValue

    this.#trySync()
  }

  #trySync() {
    if (!this.hasSyncAsValue) return

    const syncElement = document.querySelector(this.syncToValue)

    syncElement.style.setProperty(this.syncAsValue, this.inputTarget.value)
  }

  #massageIntoHex(value) {
    if (!value) return ''

    let hex = value.trim()

    if (!hex.startsWith('#')) {
      hex = `#${hex}`
    }

    const validChars = hex
      .substring(1)
      .split('')
      .filter((char) => /[0-9A-Fa-f]/.test(char))
      .join('')

    if (validChars.length === 0) return ''

    if (validChars.length === 3) {
      const r = validChars[0]
      const g = validChars[1]
      const b = validChars[2]

      return `#${r}${r}${g}${g}${b}${b}`
    }

    return `#${validChars}`
  }
}
