import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { hidden: { type: Boolean, default: true } }
  static targets = ['input', 'program', 'container']

  syncProgram({ target }) {
    if (!this.hasProgramTarget) return

    const membershipTemplateInput = target
      .closest('[data-membership-template]')
      .querySelector('input[type=checkbox]')
    const membershipPrograms = this.inputTargets.filter(
      (mp) => mp.value === target.value && !mp.hidden && mp !== target,
    )
    let program = this.programTargets.find((pt) => pt.id === target.value)

    if ((membershipTemplateInput.checked && target.checked) || membershipPrograms.some((mp) => mp.checked)) {
      program.classList.remove('hidden')
    } else {
      program.classList.add('hidden')
    }
  }

  // eslint-disable-next-line complexity
  syncPrograms({ target }) {
    if (!this.hasProgramTarget) return

    const programInputs = target
      .closest('[data-membership-template]')
      .querySelector('[data-program-container]')
      .querySelectorAll('input[type=checkbox]')
    const membershipTemplateInput = target
      .closest('[data-membership-template]')
      .querySelector('input[type=checkbox]')

    if (programInputs.length === 1) return

    for (const programInput of programInputs) {
      if (programInput.value === 'on' || programInput.type === 'hidden') continue

      const membershipPrograms = this.inputTargets.filter(
        (mp) => mp.value === programInput.value && !mp.hidden && programInput !== mp,
      )
      let program = this.programTargets.find((pt) => pt.id === programInput.value)

      if (
        (membershipTemplateInput.checked && programInput.checked) ||
        membershipPrograms.some((mp) => mp.checked)
      ) {
        program.classList.remove('hidden')
      } else {
        program.classList.add('hidden')
      }
    }
  }

  toggle() {
    this.hiddenValue = !this.hiddenValue
  }

  hiddenValueChanged(value) {
    this.containerTargets.forEach((container) => {
      container.hidden = value
      const programContainer = container.querySelector('#membership-templates-container')
      const inputs = [...programContainer.querySelectorAll('input')]
      inputs.forEach((input) => (input.disabled = value))
    })
  }
}
