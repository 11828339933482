import React, { Fragment } from 'preact/compat'
import { h } from 'preact'
import { AddressField } from './address_field'
import { useError } from './landing_page'
import { PhoneFieldWithSms, PhoneField } from './phone_field'
import { Select } from './select'
import { useId } from 'preact/hooks'

export function LeadCaptureForm({ member = {}, leadCaptureForm }) {
  const { errors } = useError()
  const id = useId()

  return (
    <>
      {leadCaptureForm.map((field) => (
        <div key={field.key}>
          <label class="flex items-center gap-1 mb-1" for={`${id}-${field.key}`}>
            <span
              class={`font-medium uppercase tracking-widest ${
                (field.key === 'name' || field.required) && 'required'
              }`}
            >
              {field.label}
            </span>
            {!field.required && <span class="text-[var(--secondary-color-light)]">(Optional)</span>}
          </label>
          {renderField(field, member, errors, `${id}-${field.key}`)}
        </div>
      ))}
    </>
  )
}

function renderField(field, member, errors, id) {
  if (field.key === 'phone') {
    return (
      <PhoneFieldWithSms
        id={id}
        number={member.phone?.number}
        sendSms={member.phone?.send_sms}
        required={field.required}
        errors={errors}
        numberChange={(number) => {
          member.phone ||= {}
          member.phone.number = number
        }}
        smsChange={(sendSms) => {
          member.phone ||= {}
          member.phone.send_sms = sendSms
        }}
      />
    )
  }

  if (field.type === 'tel') {
    return (
      <PhoneField
        id={id}
        number={member.phone?.number}
        required={field.required}
        numberChange={(number) => {
          member[field.key] = number
        }}
      />
    )
  }

  if (field.key === 'address') {
    return (
      <AddressField
        id={id}
        address={member.address || {}}
        required={field.required}
        addressChange={(address) => (member.address = address)}
      />
    )
  }

  if (field.type === 'select') {
    return <LCFSelect id={id} field={field} member={member} />
  }

  return (
    <input
      id={id}
      type={field.type}
      class={`input input-border ${field.type === 'date' ? 'h-10' : ''}`}
      placeholder="Type here"
      value={member[field.key] || ''}
      required={field.required}
      onInput={({ target }) => (member[field.key] = target.value)}
    />
  )
}

function LCFSelect({ field, id, member }) {
  return (
    <Select
      id={id}
      type={field.type}
      required={field.required}
      onInput={({ target }) => (member[field.key] = target.value)}
    >
      <option selected disabled hidden value={field.required ? '' : null}>
        Select
      </option>

      {field.options?.map((option) => (
        <option value={option} selected={member[field.key] === option}>
          {option}
        </option>
      ))}
    </Select>
  )
}
