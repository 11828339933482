import { useEffect } from 'preact/hooks'

let currentTokenizer

const tokenizerProxy = {
  submit() {
    if (!currentTokenizer) return

    const client = currentTokenizer.client
    const paymentCard = currentTokenizer.paymentCard

    paymentCard.addEventListener('error', (message) => currentTokenizer.onError({ message }), { once: true })

    client
      .getPaymentKey(paymentCard)
      .then((result) => {
        if (result.error) {
          currentTokenizer.onError(result.error)
          return
        }

        currentTokenizer.onSubmit(result)
      })
      .catch(() => {})
  },
}

export function useUsaEpayTokenizer(ref, theme, merchantAccount, onSubmit, onLoad, onError) {
  useEffect(() => {
    if (!ref.current) return

    currentTokenizer = buildUsaEpayTokenizer(ref, theme, merchantAccount, onSubmit, onLoad, onError)

    return () => (currentTokenizer = null)
  }, [ref])

  return tokenizerProxy
}

function buildUsaEpayTokenizer(ref, theme, merchantAccount, onSubmit, onLoad, onError) {
  if (!ref.current) return

  const client = new window.usaepay.Client(merchantAccount.publicKey)
  const paymentCard = client.createPaymentCardEntry()
  const style = {
    base: {
      backgroundColor: 'transparent',
      color: theme.secondary_color,
      lineHeight: '36px',
      fontFamily: '"Hind Vadodara", sans-serif',
      fontSize: '16px',
      textTransform: 'uppercase',
      height: '44px',
      border: `solid 2px ${theme.secondary_color}BF !important`,
      borderRadius: '5px !important',
      padding: '20px 10px !important',
      minWidth: '90px',
    },
    // eslint-disable-next-line max-len
    '{}\n.payjs-input-icon { display: none; } #payjs-container { height: 44px, display: flex; gap: 0.5rem; } #payjs-cnum { flex: 1 } @media (max-width: 767px) { #payjs-cvv { width: 4em; min-width: 0px !important; } #payjs-exp { width: 4em; } }':
      '',
    invalid: { color: '#d9433e' },
  }

  paymentCard.generateHTML(style)
  paymentCard.addHTML(ref.current.id)

  onLoad()

  return { client, paymentCard, onSubmit, onError }
}
