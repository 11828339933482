import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMembers, useStep, useLeadCaptureForm } from '../../landing_page'
import { cleanMember } from '../submission'

export function Members() {
  const { members } = useMembers()

  return (
    <>
      {members
        .filter((member) => Boolean(member.name))
        .map((member) => (
          <Member member={cleanMemberForDisplay(member)} />
        ))}
    </>
  )
}

function Member({ member }) {
  const { jumpToStep } = useStep()

  return (
    <div class="p-3 bg-[var(--tertiary-color)]">
      <div class="border-b flex justify-between pb-2">
        <div class="text-base font-bold">{member.name}</div>
        <button class="flex items-center gap-2" type="button" onClick={() => jumpToStep(1)}>
          <FontAwesomeIcon icon="pencil" className="text-[var(--primary-color)]" />
          <div class="uppercase font-medium tracking-wide">edit</div>
        </button>
      </div>

      <div class="space-y-2 pt-2">
        {Object.keys(member)
          .filter((k) => k !== 'name' && k !== 'primary')
          .map((key) => (
            <div class="flex items-center gap-4">
              <div class="uppercase font-medium tracking-wide">{lookupFieldLabel(member, key)}:</div>
              <div>{displayMemberInfo(member, key)}</div>
            </div>
          ))}
      </div>
    </div>
  )
}

function lookupFieldLabel(member, key) {
  if (key === 'membershipTemplate') return 'Membership'
  if (key === 'class') return 'Class'

  const { leadCaptureForm, guardianLeadCaptureForm } = useLeadCaptureForm()
  const matchKey = (field) => field.key === key
  const lcfHit = leadCaptureForm.find(matchKey)
  const glcfHit = guardianLeadCaptureForm?.find(matchKey)

  if (!member.participating && glcfHit) {
    return glcfHit.label
  }

  return lcfHit.label
}

function cleanMemberForDisplay(member) {
  const cleaned = cleanMember(member)

  if (member.participating && member.membershipTemplate) {
    cleaned.membershipTemplate = member.membershipTemplate
  }

  if (member.participating && member.class) {
    cleaned.class = member.class
  }

  return cleaned
}

function displayMemberInfo(member, key) {
  if (key === 'address' && member.address?.full) {
    return member.address.full
  }

  if (key === 'phone' && member.phone?.number) {
    return member.phone.number
  }

  if (key === 'birthday') {
    // Javascript parses 1999-03-19 wrong
    // but 1999/03/19 right...
    const value = member.birthday.replace(/-/g, '/')

    return new Date(value).toLocaleDateString()
  }

  if (key === 'membershipTemplate') {
    return (
      <div class="flex items-center gap-2 flex-wrap">
        <div>{member.membershipTemplate.name}</div>
        <div class="text-[var(--secondary-color-light)]">–</div>
        <div>{member.membershipTemplate.interval}</div>
      </div>
    )
  }

  if (key === 'class') {
    return (
      <div class="flex items-center gap-2 flex-wrap">
        <div>{member.class.name}</div>
        <div class="text-[var(--secondary-color-light)]">–</div>
        <div>
          <span>{formatDate(member.class.start)} </span>
          <span>{formatTime(member.class.start)}</span>
          <span> - </span>
          <span>{formatTime(member.class.end)}</span>
        </div>
      </div>
    )
  }

  return member[key].toString()
}

function formatDate(time) {
  const date = new Date(time)
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

function formatTime(time) {
  const date = new Date(time)

  return date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase()
}
