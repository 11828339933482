import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Error } from './landing_pages/error'

export function PhoneFieldWithSms({ number, sendSms, numberChange, smsChange, required, errors, id }) {
  const errorPresent = errors['contact_info.primary_phone_number.number']

  return (
    <>
      <div class=" flex items-center gap-3">
        <div
          class="phone-input-field"
          data-controller="phone-field--component"
          data-phone-field--component-name-value="phone"
        >
          <input
            id={id}
            type="tel"
            class={`input input-border !w-40 ${errorPresent && 'input-danger'}`}
            placeholder="201-555-0123"
            required={required}
            data-phone-field--component-target="input"
            value={number}
            onInput={({ target }) => setTimeout(() => numberChange(target.nextElementSibling.value))}
            onBlur={({ target }) => numberChange(target.nextElementSibling.value)}
            data-action="phone-field--component#setHiddenInput"
          />
        </div>

        <div class="flex items-center gap-2">
          <span>SMS OPT-IN</span>
          <input
            type="checkbox"
            class="toggle toggle-xs toggle-primary"
            checked={sendSms}
            onInput={({ target }) => smsChange(target.checked)}
          />
        </div>
      </div>

      {errorPresent && <Error message="The number you've entered is invalid. Please try again." />}

      <div class="p-3 mt-1 text-xs text-[var(--secondary-color-light)] bg-[var(--shadow-color)]">
        By opting in to SMS, the person agrees to receive announcements and billing alerts from{' '}
        {document.body.dataset.schoolName}. Standard messaging rates may apply. Messaging cadence may vary. Reply
        STOP to opt out.
      </div>
    </>
  )
}

export function PhoneField({ number, numberChange, required, id }) {
  return (
    <div
      class="phone-input-field"
      data-controller="phone-field--component"
      data-phone-field--component-name-value="phone"
    >
      <input
        id={id}
        type="tel"
        class={`input input-border !w-40`}
        placeholder="201-555-0123"
        required={required}
        data-phone-field--component-target="input"
        value={number}
        autocomplete="off"
        onInput={({ target }) => setTimeout(() => numberChange(target.nextElementSibling.value))}
        onBlur={({ target }) => numberChange(target.nextElementSibling.value)}
        data-action="phone-field--component#setHiddenInput"
      />
    </div>
  )
}
