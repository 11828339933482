import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useMerchandise, useStep } from '../landing_page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ItemEditor, Money, useCart } from './cart'

export function Merchandise() {
  const { nextStep, previousStep } = useStep()
  const merchandise = useMerchandise()

  const outOfStockClasses = 'flex items-center gap-2 text-[var(--secondary-color-light)] cursor-not-allowed pr-4'
  const itemClasses = 'py-3 mx-3 flex gap-3 justify-between last:border-none border-b border-[var(--shadow-color)]'

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()

        nextStep()
      }}
    >
      <div class="p-3 bg-[var(--shadow-color)]">
        <div class="p-3 bg-[var(--tertiary-color)] space-y-3">
          {Object.entries(merchandise).map(([category, items]) => (
            <div class="border-x border-b border-[var(--shadow-color)]">
              <button
                type="button"
                class="bg-[var(--shadow-color)] flex w-full py-2 px-2.5 uppercase font-medium tracking-wider"
              >
                {category}
              </button>

              {items.map((item) => (
                <div class={itemClasses}>
                  <div className="flex flex-col gap-2">
                    <div>{item.name}</div>

                    <Money amount={item.price} />
                  </div>

                  {!item.deliverable || (item.remaining && item.remaining > 0) ? (
                    <Add item={item} />
                  ) : (
                    <div class={outOfStockClasses}>
                      <span>Out of Stock</span>
                      <FontAwesomeIcon icon={['far', 'circle-xmark']} className="size-4" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div class="flex justify-end items-center gap-1 mt-4 pb-4">
        <button class="btn btn-back" type="button" onClick={previousStep}>
          BACK
        </button>
        <button class="btn" type="submit">
          NEXT
        </button>
      </div>
    </form>
  )
}

function Add({ item }) {
  const { cart, addToCart } = useCart()

  const animationClasses =
    'opacity-100 hidden:opacity-0 transition-discrete transition-all starting:opacity-0 duration-250 ease-in-out'

  return (
    <div class="flex items-center">
      <div class="grid items-center justify-items-end h-12 [&>*]:col-start-1 [&>*]:row-start-1">
        <div class={animationClasses} hidden={!cart.has(item)}>
          <ItemEditor item={item} />
        </div>

        <button
          class={`active:scale-95 pl-4 py-2 pr-4 ${animationClasses}`}
          onClick={() => addToCart(item)}
          type="button"
          hidden={cart.has(item)}
        >
          <FontAwesomeIcon icon="circle-plus" className="text-[var(--primary-color)] size-4" />
        </button>
      </div>
    </div>
  )
}
