import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useContext, useCallback } from 'preact/hooks'
import { useBilling, LandingPageState } from '../landing_page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function useCart() {
  const { cart } = useContext(LandingPageState)
  const { merchantAccount } = useBilling()

  const addToCart = useCallback((item) => {
    const _cart = new Map(cart.value)

    if (_cart.has(item)) {
      const current = _cart.get(item)

      const max = item.remaining || 99

      _cart.set(item, Math.min(current + 1, max))
    } else {
      _cart.set(item, 1)
    }

    cart.value = _cart
  })

  const removeFromCart = useCallback((item) => {
    const _cart = new Map(cart.value)

    if (_cart.get(item) > 1) {
      const current = _cart.get(item)

      _cart.set(item, current - 1)
    } else {
      _cart.delete(item)
    }

    cart.value = _cart
  })

  const setQuantity = useCallback((item, quantity) => {
    const _cart = new Map(cart.value)

    quantity ||= 0

    const max = item.remaining || 99

    _cart.set(item, Math.min(Math.max(quantity, 0), max))

    cart.value = _cart
  })

  const isMembership = (item) => 'registration_fee_price' in item

  const priceOf = (item) => {
    if (isMembership(item)) {
      return parseFloat(item.price) + (parseFloat(item.registration_fee_price) || 0)
    }

    return parseFloat(item.price)
  }

  const taxOf = (item) => {
    if (isMembership(item)) {
      return parseFloat(item.tax) + (parseFloat(item.registration_fee_tax) || 0)
    }

    return parseFloat(item.tax)
  }

  const subtotal = () => {
    const entries = Array.from(cart.value.entries())

    return bankersRound(entries.reduce((acc, [item, quantity]) => acc + quantity * priceOf(item), 0))
  }
  const tax = () => {
    const entries = Array.from(cart.value.entries())

    return bankersRound(entries.reduce((acc, [item, quantity]) => acc + quantity * taxOf(item), 0))
  }
  const serviceFee = () => {
    if (!merchantAccount) return 0
    if (!merchantAccount.serviceFeePercentage) return 0

    return bankersRound((subtotal() + tax()) * merchantAccount.serviceFeePercentage)
  }
  const total = () => subtotal() + tax() + serviceFee()

  return {
    cart: cart.value,
    addToCart,
    removeFromCart,
    setQuantity,
    total,
    subtotal,
    tax,
    serviceFee,
    priceOf,
    isMembership,
  }
}

export function Cart() {
  const { cart } = useCart()
  const entries = Array.from(cart.entries())
  const total = entries.reduce((acc, [_, quantity]) => acc + quantity, 0)

  const iconClasses =
    'absolute top-[-6px] left-1/2 px-[0.5em] py-[0.125em] leading-none bg-danger-600 text-white rounded-full ' +
    'flex justify-center items-center text-[0.875em]'
  let buttonClasses = 'relative flex items-center size-5'

  const dialogClasses =
    'm-0 absolute bg-[var(--tertiary-color)] text-[var(--secondary-color)] p-3 z-50 shadow-lg outline-none ' +
    '[&[open]]:animate-in animate-out [&[open]]:fade-in-0 fade-out-0 [&[open]]:zoom-in-95 zoom-out-95 min-w-64 ' +
    'max-w-xs'

  return (
    <div class="pl-2">
      <div data-controller="popover" data-popover-placement-value="bottom-end">
        <button class={buttonClasses} type="button" data-action="popover#toggle" data-popover-target="trigger">
          {total > 0 ? (
            <>
              <CartIcon class="text-[var(--primary-color)] fa-fw" />
              <span class={iconClasses}>{total}</span>
            </>
          ) : (
            <CartIcon class="text-basic-700 fa-fw" />
          )}
        </button>
        <dialog data-popover-target="body" class={dialogClasses}>
          <div class="font-bold mb-3">Cart</div>
          {total > 0 ? (
            <CartDisplay detailed={false} />
          ) : (
            <div class="text-[var(--secondary-color-light)]">Your cart is empty</div>
          )}
        </dialog>
      </div>
    </div>
  )
}

export function CartDisplay({ detailed }) {
  const { cart, priceOf, isMembership, total, subtotal, tax, serviceFee } = useCart()
  const entries = Array.from(cart.entries())

  return (
    <div class="space-y-3">
      {entries.map(([item, quantity]) => (
        <>
          <div>
            <div class="flex justify-between items-center gap-4 mb-1">
              <div class={detailed ? null : 'truncate'}>{item.name}</div>
              {priceOf(item) > 0 ? (
                <div class="font-bold">
                  <Money amount={priceOf(item) * quantity} />
                </div>
              ) : (
                <div>Free</div>
              )}
            </div>
            <div class="flex justify-between items-center gap-4">
              {!isMembership(item) && <ItemEditor item={item} />}

              {quantity > 1 && (
                <div class="flex items-center gap-1">
                  <Money amount={priceOf(item)} />
                  <span>/ ea</span>
                </div>
              )}
            </div>
          </div>

          <hr class="border-secondary" />
        </>
      ))}

      <div className="flex items-center justify-end gap-3">
        <div class="uppercase font-medium tracking-wider">Subtotal:</div>
        <div class="font-semibold w-[4.5rem]">
          <Money amount={subtotal()} class="text-end" />
        </div>
      </div>

      {detailed && (
        <>
          <div className="flex items-center justify-end gap-3">
            <div class="uppercase font-medium tracking-wider">Tax:</div>
            <div class="font-semibold w-[4.5rem]">
              <Money amount={tax()} class="text-end" />
            </div>
          </div>

          {serviceFee() > 0 && (
            <div className="flex items-center justify-end gap-3">
              <div class="uppercase font-medium tracking-wider">Service Fee:</div>
              <div class="font-semibold w-[4.5rem]">
                <Money amount={serviceFee()} class="text-end" />
              </div>
            </div>
          )}

          <hr class="border-secondary" />

          <div className="flex items-center justify-end gap-3">
            <div class="uppercase font-medium tracking-wider">Total Due:</div>
            <div class="font-semibold w-[4.5rem]">
              <Money amount={total()} class="text-end" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

function bankersRound(num) {
  const multiplier = 10 ** 2
  const x = num * multiplier
  const roundedX = Math.round(x)
  const fraction = Math.abs(x - roundedX)

  if (fraction === 0.5) {
    return (roundedX % 2 === 0 ? roundedX : roundedX - 1) / multiplier
  }

  return roundedX / multiplier
}

export function Money({ amount, ...props }) {
  const formatted = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: document.body.dataset.currency,
    currencySign: 'standard',
  }).format(amount)

  return <div {...props}>{formatted}</div>
}

export function ItemEditor({ item }) {
  const { cart, addToCart, removeFromCart, setQuantity } = useCart()
  const quantity = cart.get(item)

  const inputClasses =
    'no-spinner appearance-none bg-[var(--shadow-color)] text-center border-b-2 border-transparent ' +
    'focus:outline-none focus:border-[var(--primary-color)]'

  return (
    <div class="rounded-full bg-[var(--shadow-color)] flex justify-between items-center w-24">
      <button
        class="pl-3 py-1.5 flex-1 grid [&>*]:col-start-1 [&>*]:row-start-1"
        type="button"
        onClick={() => removeFromCart(item)}
      >
        {quantity > 1 ? (
          <FontAwesomeIcon icon="square-minus" className={`size-5 text-[var(--secondary-color-light)]`} />
        ) : (
          <div class={`flex items-center justify-center size-5`}>
            <FontAwesomeIcon icon="trash" className={`size-4 text-[var(--secondary-color-light)]`} />
          </div>
        )}
      </button>

      <input
        type="number"
        value={quantity}
        class={inputClasses}
        onInput={({ target }) => {
          const newQuantity = Number(target.value)

          setQuantity(item, newQuantity)
        }}
        onBlur={({ target }) => {
          let newQuantity = Number(target.value)

          if (newQuantity < 1) {
            newQuantity = 1
          }

          setQuantity(item, newQuantity)
        }}
        style={`width: calc(${quantity?.toString()?.length || 1}ch + 1rem)`}
      />

      <button
        class="flex justify-end flex-1 pr-3 py-1.5 disabled:opacity-65 disabled:cursor-not-allowed"
        type="button"
        disabled={item.remaining && cart.get(item) >= item.remaining}
        onClick={() => addToCart(item)}
      >
        <FontAwesomeIcon icon="square-plus" className="size-5 text-[var(--secondary-color-light)]" />
      </button>
    </div>
  )
}

function CartIcon(props) {
  const path =
    'M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5  ' +
    '31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24  ' +
    '24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 ' +
    '37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor" {...props}>
      <path d={path} />
    </svg>
  )
}
