import { useClickOutside, useHotkeys } from 'stimulus-use'
import ApplicationController from '../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['dialog']
  static values = { turbo: Boolean, closable: { default: true, type: Boolean } }

  connect() {
    if (this.closableValue) {
      useClickOutside(this, { element: this.dialogTarget, events: ['mousedown'] })
      useHotkeys(this, { esc: [this.hide.bind(this)] })
    }

    // We have to wait for the modal to get rendered in the
    // DOM before we can actually show it
    if (this.turboValue) {
      window.FontAwesome?.dom?.i2svg()
      setTimeout(this.show.bind(this), this.delay)
    }
  }

  show() {
    this.hideAllTooltips()

    const currentWidth = document.body.offsetWidth

    this.element.classList.add('open')

    this.waitForAnimations(this.element, () => {
      this.focus(this.element.querySelector('[autofocus]'))
    })

    document.body.style.marginRight = `${document.body.offsetWidth - currentWidth}px`
  }

  confirm() {
    this.element.classList.remove('open', 'in')

    this.waitForAnimations(this.element, () => {
      this.element.dispatchEvent(new CustomEvent('modal:hidden', { detail: { finished: true } }))
    })
  }

  hide() {
    this.element.classList.remove('open', 'in')

    this.waitForAnimations(this.element, () => {
      this.element.dispatchEvent(new CustomEvent('modal:hidden', { detail: { finished: false } }))
    })

    document.body.style.marginRight = null
  }

  clickOutside(event) {
    // Otherwise this gets called for hidden modals like the confirm modal
    if (getComputedStyle(this.dialogTarget).visibility === 'hidden') return
    if (event.target.clientWidth < event.offsetX) return

    if (this.isUserback(event)) return
    if (event.target.closest('.pac-container')) return

    const calendars = Array.from(document.querySelectorAll('.flatpickr-calendar'))
    const hasTarget = calendars.filter((calendar) => calendar.contains(event.target))

    if (hasTarget.length === 0) {
      this.hide()
    }
  }

  ///
  /// private
  ///
  hideAllTooltips() {
    const tooltips = document.querySelectorAll('.tooltip')

    for (const tooltip of [...tooltips]) {
      tooltip.remove()
    }
  }

  isUserback({ target }) {
    if (target.tagName === 'UBDIV') return true

    const toolbar = target.closest('utoolbar')

    if (toolbar) return true

    const overlay = target.closest('.userback-overlay')

    if (overlay) return true

    return false
  }

  get delay() {
    return 100
  }
}
