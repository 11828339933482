import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { CartDisplay, useCart } from '../cart'

export function Cart() {
  const { cart } = useCart()

  return (
    <>
      {cart.size > 0 && (
        <div class="p-3 bg-[var(--tertiary-color)]">
          <div class="text-base font-bold">Cart</div>

          <CartDisplay detailed={true} />
        </div>
      )}
    </>
  )
}
