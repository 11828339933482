import { useEffect } from 'preact/hooks'

let currentTokenizer

const tokenizerProxy = {
  submit() {
    if (!currentTokenizer) return

    currentTokenizer.submit()
  },
}

export function useBasysTokenizer(ref, theme, merchantAccount, onSubmit, onLoad, onError) {
  useEffect(() => {
    if (!ref.current) return

    currentTokenizer = buildBasysTokenizer(ref, theme, merchantAccount, onSubmit, onLoad, onError)

    return () => (currentTokenizer = null)
  }, [ref])

  return tokenizerProxy
}

function buildBasysTokenizer(ref, theme, merchantAccount, onSubmit, onLoad, onError) {
  if (!ref.current) return

  return new window.Tokenizer({
    apikey: merchantAccount.publicKey,
    container: ref.current,
    submission: (response) => {
      switch (response.status) {
        case 'success':
          onSubmit(response.token)

          break
        case 'error':
          onError(response.msg)

          break
        case 'validation':
          onError('Invalid card information')

          break
        default:
          break
      }
    },
    onLoad,
    settings: {
      payment: { placeholderCreditCard: 'CARD NUMBER' },
      styles: {
        '.payment input': {
          height: '44px',
          border: `solid 2px ${theme.secondary_color}BF`,
          'border-radius': '5px',
          'background-color': 'transparent',
          color: `${theme.secondary_color}`,
          'line-height': '36px',
          'font-family': '"Hind Vadodara", sans-serif',
          'font-size': '16px',
          'text-transform': 'uppercase',
        },
        '.payment input.invalid': { border: `solid 2px #d9433e` },
      },
    },
  })
}
