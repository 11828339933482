import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useSignal } from '@preact/signals'

export function Collapsible({ defaultOpen = false, Trigger, Content }) {
  const open = useSignal(defaultOpen)

  const toggle = () => (open.value = !open.value)

  const containerClasses = open.value ? 'h-auto border-[var(--tertiary-color)]' : 'h-0 border-transparent'

  return (
    <>
      <button type="button" class="py-2 px-2.5 flex items-center justify-between w-full" onClick={toggle}>
        {Trigger}

        <Chevron class={open.value ? 'size-4 rotate-180 transition-all' : 'size-4 rotate-0 transition-all'} />
      </button>
      <div
        class={`border-t ${containerClasses} transition-all ease-in-out duration-300 overflow-hidden`}
        style={{ interpolateSize: 'allow-keywords', transitionBehavior: 'allow-discrete' }}
      >
        <div class="p-3">{Content}</div>
      </div>
    </>
  )
}

export function Chevron(props) {
  const d =
    'M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 ' +
    '0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" {...props}>
      <path d={d} />
    </svg>
  )
}
