import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useSignal, useSignalEffect } from '@preact/signals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMembershipTemplates, useError } from '../landing_page'
import { Collapsible } from './collapsible'
import { Money, useCart } from './cart'
import { Error } from './error'
import { Select } from '../select'

export function MembershipSelection({ member, completed, selectedTemplate, selectedProgram }) {
  const { errors, clearError } = useError()
  const { addToCart, removeFromCart } = useCart()
  const membershipTemplates = useMembershipTemplates()

  const classes = 'size-2 rounded-full ring-2 ring-offset-[var(--shadow-color)] ring-offset-2'

  useSignalEffect(() => {
    if (selectedTemplate.value) {
      clearError('membership')
    }
  })

  useSignalEffect(() => {
    const mt = selectedTemplate.value

    if (mt && mt === member.membershipTemplate) {
      completed.value = true
      return
    }

    member.class = null

    if (mt) {
      const manyPrograms = mt.programs && mt.programs.length > 1
      member.membershipTemplate = mt

      if (manyPrograms) {
        completed.value = false
        member.program = null
        selectedProgram.value = null
        return
      }

      if (mt.programs) {
        member.program = mt.programs[0]
        selectedProgram.value = mt.programs[0]
      } else {
        member.program = null
        selectedProgram.value = null
      }

      completed.value = true

      return
    }

    completed.value = false
    member.membershipTemplate = null
    member.program = null
  })

  return (
    <>
      <div
        class={`bg-[var(--shadow-color)] border ${errors.membership ? 'border-danger-600' : 'border-transparent'}`}
      >
        <Collapsible
          defaultOpen={true}
          Trigger={
            <>
              <div class="flex items-center gap-2 size">
                <div class="text-base font-bold">Membership</div>
                {completed.value && (
                  <div class="size-4 bg-[var(--tertiary-color)] rounded-full">
                    <FontAwesomeIcon icon="circle-check" className="text-[var(--primary-color)] size-4 block" />
                  </div>
                )}
              </div>
            </>
          }
          Content={
            <div class="space-y-3">
              {membershipTemplates.map((membershipTemplate) => (
                <MembershipTemplate
                  member={member}
                  membershipTemplate={membershipTemplate}
                  onSelect={(event) => {
                    if (
                      event.target.tagName === 'SELECT' ||
                      event.target.tagName === 'LABEL' ||
                      event.target.tagName === 'OPTION'
                    ) {
                      return
                    }

                    if (selectedTemplate.value === membershipTemplate) {
                      removeFromCart(membershipTemplate)
                      selectedTemplate.value = null
                      return
                    }

                    removeFromCart(selectedTemplate.value)
                    addToCart(membershipTemplate)
                    selectedTemplate.value = membershipTemplate
                  }}
                  onProgramSelect={({ target }) => {
                    completed.value = true
                    const program = selectedTemplate.value.programs.find((p) => p.id.toString() === target.value)
                    member.class = null
                    member.program = program
                    selectedProgram.value = program
                  }}
                  selected={selectedTemplate.value === membershipTemplate}
                />
              ))}
            </div>
          }
        />
      </div>

      {errors.membership && <Error message="Select a Membership" />}
    </>
  )
}

function MembershipTemplate({ member, membershipTemplate, onSelect, onProgramSelect, selected = false }) {
  const classes =
    'block relative w-full bg-[var(--tertiary-color)] p-3 rounded text-left border-2 transition-colors'

  let selectedClasses = 'border-transparent hover:border-[var(--primary-color)]/75'

  if (selected) {
    selectedClasses = 'border-[var(--primary-color)]'
  }

  return (
    <button type="button" onClick={onSelect} class={`${classes} ${selectedClasses}`}>
      {selected && (
        <div class="absolute -left-2 -top-2 bg-[var(--tertiary-color)] size-4 rounded-full">
          <FontAwesomeIcon icon="circle-check" className="block text-[var(--primary-color)] size-4" />
        </div>
      )}

      <MembershipTemplateDetails membershipTemplate={membershipTemplate} />
      {membershipTemplate.description && <div class="mt-2">{membershipTemplate.description}</div>}

      {membershipTemplate.programs && (
        <>
          <div class="my-2 w-full border-t border-[var(--shadow-color)]"></div>
          {selected && membershipTemplate.programs.length > 1 ? (
            <ProgramSelector
              member={member}
              membershipTemplate={membershipTemplate}
              onProgramSelect={onProgramSelect}
            />
          ) : (
            <div>{membershipTemplate.programs.map((p) => p.name).join(', ')}</div>
          )}
        </>
      )}
    </button>
  )
}

function MembershipTemplateDetails({ membershipTemplate }) {
  return (
    <div class="flex items-center justify-between w-full">
      <div class="flex gap-x-3 items-center flex-wrap">
        <div class="font-bold">{membershipTemplate.name}</div>
        {membershipTemplate.kind === 'trial' && <div class="pill">trial</div>}
        <div>{membershipTemplate.interval}</div>
      </div>

      <div class="space-y-1 text-right">
        <div>
          {membershipTemplate.price ? (
            <div class="flex justify-end items-center gap-1 whitespace-nowrap">
              <Money amount={membershipTemplate.price} />
              {membershipTemplate.billing_interval && <div>{membershipTemplate.billing_interval}</div>}
            </div>
          ) : (
            'Free'
          )}
        </div>
        {membershipTemplate.registration_fee_price > 0 && (
          <div class="flex justify-end items-center gap-1 whitespace-nowrap">
            <Money amount={membershipTemplate.registration_fee_price} />
            <div>Reg. Fee</div>
          </div>
        )}
      </div>
    </div>
  )
}

function ProgramSelector({ member, membershipTemplate, onProgramSelect }) {
  return (
    <div>
      <label
        for={`programs-${membershipTemplate.id}`}
        class="block font-medium uppercase tracking-widest required mb-1"
      >
        program
      </label>
      <Select id={`programs-${membershipTemplate.id}`} onInput={onProgramSelect} required>
        <option selected hidden disabled value="">
          Select a Program
        </option>
        {membershipTemplate.programs.map((program) => (
          <option value={program.id} selected={program.id === member.program?.id}>
            {program.name}
          </option>
        ))}
      </Select>
    </div>
  )
}
