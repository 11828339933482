import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useBilling } from '../../landing_page'

export function Payment({ PaymentElement }) {
  const { paymentRequired } = useBilling()

  return (
    <>
      {paymentRequired && (
        <div class="p-3 bg-[var(--tertiary-color)]">
          <div class="text-base font-bold">Card Details</div>
          {PaymentElement}
        </div>
      )}
    </>
  )
}
