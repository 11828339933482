import React from 'preact/compat'
import { h, Fragment } from 'preact'
import { useSubmit } from './submission'
import { useBilling, usePreview } from '../landing_page'
import { GenericConfirmation } from './confirmation/generic'
import { BasysConfirmation } from './confirmation/basys'
import { StripeConfirmation } from './confirmation/stripe'
import { UsaEpayConfirmation } from './confirmation/usa_epay'
import { useCart } from './cart'
import { useSignal } from '@preact/signals'

export function Confirmation() {
  const { paymentRequired } = useBilling()
  const { total } = useCart()
  const isPreview = usePreview()

  return <>{!isPreview && paymentRequired && total() > 0 ? <BillingConfirmation /> : <NoBillingConfirmation />}</>
}

function BillingConfirmation() {
  const { merchantAccount } = useBilling()

  return (
    <>
      {merchantAccount.type === 'basys' && <BasysConfirmation />}
      {merchantAccount.type === 'stripe' && <StripeConfirmation />}
      {merchantAccount.type === 'usa_epay' && <UsaEpayConfirmation />}
    </>
  )
}

function NoBillingConfirmation() {
  const { submit } = useSubmit()
  const submitDisabled = useSignal(false)

  return (
    <GenericConfirmation
      submitDisabled={submitDisabled.value}
      onSubmit={async (event) => {
        event.preventDefault()
        submitDisabled.value = true
        await submit()
        submitDisabled.value = false
      }}
    />
  )
}
