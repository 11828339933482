import React from 'preact/compat'
import { h, Fragment } from 'preact'

export function Select({ children, ...props }) {
  const chevronClasses =
    "absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none after:content-[''] after:absolute " +
    'after:border-[4px] after:border-t-current after:border-x-transparent after:border-b-0 w-2 h-1'

  return (
    <div class="relative">
      <select class="input input-border appearance-none cursor-pointer [&>*]:bg-[var(--shadow-color)]" {...props}>
        {children}
      </select>
      <div class={chevronClasses}></div>
    </div>
  )
}
