import React, { Fragment } from 'preact/compat'
import { h } from 'preact'
import { useStep } from '../landing_page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function StepTracker() {
  const { step, numberOfSteps } = useStep()

  const steps = Array.from(Array(numberOfSteps).keys()).map((i) => i + 1)

  return (
    <div class="flex items-center">
      {steps.map((s) => (
        <>
          <Step displaying={s} current={step} />

          {s < numberOfSteps && (
            <div
              class={s < step ? 'bg-[var(--primary-color)] h-1 w-2' : 'bg-[var(--secondary-color-light)] h-1 w-2'}
            ></div>
          )}
        </>
      ))}
    </div>
  )
}

function Step({ displaying, current }) {
  const classes = 'size-2 rounded-full ring-2 ring-offset-[var(--tertiary-color)] ring-offset-2'

  const isFinished = displaying < current
  const isCurrent = displaying === current
  const isNext = displaying > current

  return (
    <>
      {isFinished && <FontAwesomeIcon icon="circle-check" className="text-[var(--primary-color)] size-4" />}
      {isCurrent && (
        <div class="size-4 bg-[var(--tertiary-color)] rounded-full flex items-center justify-center">
          <div class={`${classes} bg-[var(--primary-color)] ring-[var(--primary-color)]`}></div>
        </div>
      )}
      {isNext && (
        <div class="size-4 bg-[var(--tertiary-color)] rounded-full flex items-center justify-center">
          <div class={`${classes} bg-[var(--tertiary-color)] ring-[var(--secondary-color-light)]`}></div>
        </div>
      )}
    </>
  )
}
